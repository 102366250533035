import React from "react";
import "./style.css";
import failedImg from "../../../assets/images/failed-img.png";
import { Button } from "@mui/material";
import { STUDENT_RESCHEDULE_PAGE } from "../../../config/constants/routePathConstant";
import { storeLocal } from "../../../utils/projectHelper";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

const FailedPage = () => {
  const { candidateId } = useParams();
  const navigate = useNavigate();

  const handleSubmit = () => {
    storeLocal("true", "reAttempt");
    navigate(`${STUDENT_RESCHEDULE_PAGE}/${candidateId}`);
  };
  return (
    <div className="failed-page">
        <Helmet>
        <script>
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3654106,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
      <div className="container">
        <div className="main">
          <div className="img">
            <img src={failedImg} alt={"failed-img"} />
          </div>
          <h2 className="title">Sorry, you did not pass</h2>
          <p className="desc">Your score is less than 40%</p>
          <div className="re-attempt-btn-wrapper">
            <Button
              className="re-attempt-btn"
              sx={{ padding: "12px 23px" }}
              onClick={handleSubmit}
            >
              Re Attempt Exam
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedPage;
