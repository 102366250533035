// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer-box{
  height: calc(100vh - 96px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.student-page footer {
  float: left;
  width: 100%;
}

.student-footer {
  max-width: 1776px;
  display: flex;
  align-items: center;
  margin: auto;
  width: 90%;
  height: 45px;
}
.student-footer h1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.3);
  margin-right:  21px;
}


/* responsive for 1200px tp 1599px  */

@media screen and (min-width: 1200px) and (max-width: 1599px) {
  .student-footer .logo img {
    max-width: 110px;
    width: 100%;
  }
}
@media (min-width: 1200px) and (max-width: 1439px){
  .student-footer  {
    max-width: 1140px;
  }
}
@media (min-width: 1440px) and (max-width: 1860px){
  .student-footer  {
    max-width: 1330px;
  }
}
@media(max-width:900px){
  .outer-box{
    width: 100%;
    display: flex;
    align-items: center;
  }
}
@media (max-width:500px) {
  .student-footer h1 {
    font-size: 12px;
  }
  .student-footer {
    justify-content: center;
  }
  .student-footer .logo img {
    max-width: 90px;
    width: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/layout/footer/style.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,WAAW;AACb;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,UAAU;EACV,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;AACrB;;;AAGA,qCAAqC;;AAErC;EACE;IACE,gBAAgB;IAChB,WAAW;EACb;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,iBAAiB;EACnB;AACF;AACA;EACE;IACE,WAAW;IACX,aAAa;IACb,mBAAmB;EACrB;AACF;AACA;EACE;IACE,eAAe;EACjB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,eAAe;IACf,WAAW;EACb;AACF","sourcesContent":[".outer-box{\n  height: calc(100vh - 96px);\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.student-page footer {\n  float: left;\n  width: 100%;\n}\n\n.student-footer {\n  max-width: 1776px;\n  display: flex;\n  align-items: center;\n  margin: auto;\n  width: 90%;\n  height: 45px;\n}\n.student-footer h1 {\n  font-family: \"Poppins\";\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 22px;\n  color: rgba(0, 0, 0, 0.3);\n  margin-right:  21px;\n}\n\n\n/* responsive for 1200px tp 1599px  */\n\n@media screen and (min-width: 1200px) and (max-width: 1599px) {\n  .student-footer .logo img {\n    max-width: 110px;\n    width: 100%;\n  }\n}\n@media (min-width: 1200px) and (max-width: 1439px){\n  .student-footer  {\n    max-width: 1140px;\n  }\n}\n@media (min-width: 1440px) and (max-width: 1860px){\n  .student-footer  {\n    max-width: 1330px;\n  }\n}\n@media(max-width:900px){\n  .outer-box{\n    width: 100%;\n    display: flex;\n    align-items: center;\n  }\n}\n@media (max-width:500px) {\n  .student-footer h1 {\n    font-size: 12px;\n  }\n  .student-footer {\n    justify-content: center;\n  }\n  .student-footer .logo img {\n    max-width: 90px;\n    width: 100%;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
