import { lazy } from "react";
import StudentLayout from "../../components/common/layout/StudentLayout";
import {
  REGISTER,
  STUDENT_CAPTURE_FACE_PAGE,
  STUDENT_GENERAL_INSTRUCTIONS_PAGE,
  STUDENT_START_ASSESSMENT_PAGE,
  STUDENT_ASSESSMENT_PAGE,
  STUDENT_SUCCESS_PAGE,
  STUDENT_COUNT_DOWN_PAGE,
  STUDENT_RESCHEDULE_PAGE,
  STUDENT_TIME_OUT_PAGE,
  STUDENT_RESULT_PAGE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  OTP,
  ADMIN_SIGNIN,
} from "./../constants/routePathConstant";

const AdminLogin = lazy(() =>import("../../pages/admin/login"));
const Login = lazy(() => import("../../pages/auth/login"));
const Register = lazy(() => import("../../pages/auth/register"));
const ForgotPassword = lazy(() => import("../../pages/auth/forgotPassword"));
const ResetPassword = lazy(() => import("../../pages/auth/resetPassword"));
const Otp = lazy(() => import("../../pages/auth/otp"));

const CaptureFace = lazy(() => import("../../pages/capture"));
const GeneralInstructions = lazy(() =>
  import("../../pages/generalInstructions")
);
const StartAssessment = lazy(() => import("../../pages/assessment"));
const Question = lazy(() => import("../../pages/questionLists/questions"));
const SuccessPage = lazy(() => import("../../pages/questionLists/success"));
const CountDownPage = lazy(() => import("../../pages/countDown"));
const ReschedulePage = lazy(() => import("../../pages/reschedule"));
const TimeoutPage = lazy(() => import("../../pages/timeOut"));
const NotFound = lazy(() => import("../../pages/notFound"));

const guestRoutes = [
  {
    path: "/",
    element: <StudentLayout />,
    children: [
      {
        index: true,
        element: <Login />,
      },
      {
        path: REGISTER,
        element: <Register />,
      },
      {
        path: FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: `${RESET_PASSWORD}/:mobile`,
        element: <ResetPassword />,
      },
      {
        path: `${OTP}/:mobile`,
        element: <Otp />,
      },
      {
        path: `${STUDENT_CAPTURE_FACE_PAGE}/:candidateId`,
        element: <CaptureFace />,
      },
      {
        path: `${STUDENT_START_ASSESSMENT_PAGE}/:candidateId`,
        element: <StartAssessment />,
      },
      {
        path: `${STUDENT_GENERAL_INSTRUCTIONS_PAGE}/:candidateId`,
        element: <GeneralInstructions />,
      },
      {
        path: `${STUDENT_ASSESSMENT_PAGE}/:candidateId`,
        element: <Question />,
      },
      {
        path: `${STUDENT_SUCCESS_PAGE}/:candidateId`,
        element: <SuccessPage />,
      },
      {
        path: `${STUDENT_RESULT_PAGE}/:candidateId`,
        element: <SuccessPage alreadyLogin/>,
      },
      {
        path: `${STUDENT_COUNT_DOWN_PAGE}/:candidateId`,
        element: <CountDownPage />,
      },
      {
        path: `${STUDENT_RESCHEDULE_PAGE}/:candidateId`,
        element: <ReschedulePage />,
      },
      {
        path: `${STUDENT_TIME_OUT_PAGE}/:candidateId`,
        element: <TimeoutPage />,
      },
      {
        path: ADMIN_SIGNIN,
        element: <AdminLogin />,
      },
      {
        path: "*",
        element: <NotFound/>,
      },
    ],
  },
];

export default guestRoutes;
