import { createSlice } from "@reduxjs/toolkit";

const studentSlice = createSlice({
  name: "student",
  initialState: {
    navPath: null,
    questionList: [],
    startTime: "",
    reportList: [],
    candidateLists: [],
    assessmentInfo: {},
    candidateCounts: {
      totalCandidateRegCount: 0,
      totalCandidateResult: 0,
      totalCandidateRegCountToday: 0,
      totalCandidateResultToday: 0,
      liveTestAttempt: 0,
      stateWiseCount: {},
      stateWiseReportCount: {},
    },
    overAllMap: [],
  },
  reducers: {
    navigatePath: (state, { payload }) => {
      state.navPath = payload;
    },
    getQuestionList: (state, { payload }) => {
      state.questionList = payload;
    },
    getStartTime: (state, { payload }) => {
      state.startTime = payload;
    },
    getAssessmentInfo: (state, { payload }) => {
      state.assessmentInfo = payload;
    },
    //admin
    getReportLists: (state, { payload }) => {
      state.reportList = payload;
    },
    getCandidateLists: (state, { payload }) => {
      state.candidateLists = payload;
    },
    getCandidateCounts: (state, { payload }) => {
      state.candidateCounts = payload;
    },
  },
});

export const {
  navigatePath,
  getQuestionList,
  getReportLists,
  getCandidateLists,
  getStartTime,
  getAssessmentInfo,
  getCandidateCounts,
} = studentSlice.actions;

export const studentSelector = (state) => state.student;
const studentReducer = studentSlice.reducer;
export default studentReducer;
