import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import "./style.css";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close-icon.svg";
import { ReactComponent as RatingsThumbs } from "../../../../assets/images/ratingsThumbs.svg";

const RatingModal = ({ title, onClose, content, open, handleCancel, handleAccept }) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dialog-wrapper"
      >
        <Box className="rate-us-modal">
          <Box className="close-btn-wrapper" onClick={handleCancel}>
            <CloseIcon/>
          </Box>
          <Box className="modal-content-wrapper">
            <Box className="rating-thumb-wrapper" >
              <RatingsThumbs />
            </Box>
            <Box className="title-wrapper">
              <DialogTitle
                id="alert-dialog-title"
                sx={{ padding: 0 }}
                className="title"
              >
                <Box>
                  <h6>{title}</h6>
                </Box>
              </DialogTitle>
            </Box>

            <DialogContent sx={{ padding: 0 }} className="content-body">
              <Box>
                <h6>{content}</h6>
              </Box>
              <DialogActions sx={{ padding: 0 }}>
                <Box className="action-btn-wrapper">
                  <Button className="rate-us-btn" onClick={handleAccept}>
                    {"Rate us on Google "}
                  </Button>
                  {/* <Button className="not-now-btn" onClick={handleCancel}>
                    {"Not Now"}
                  </Button> */}
                </Box>
              </DialogActions>
            </DialogContent>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default RatingModal;
