import api from "../utils/apiHelper.js";
import { devConsoleLog, errorToast, navigate, sessionDestroy, storeLocal, successToast } from "../utils/projectHelper";

import {
  ADMIN_SIGNIN_API,
  API_ROOT,
  CAPTURE_IMAGE_API,
  CHECK_TEST_ATTENDED_API,
  FORGOT_PASSWORD_API,
  GET_COUNTDOWN_TIMER_API,
  GET_DISTRICTS_API,
  GET_QUESTIONS_API,
  GET_REPORT_DETAILS_API,
  GET_SLOT_LIST_API,
  GET_STATES_API,
  GET_TEST_START_TIME_API,
  POST_TEST_START_TIME_API,
  RESET_PASSWORD_API,
  RE_ARRANGE_TIME_SLOT_API,
  SIGNIN_API,
  SIGNUP_API,
  SUBMIT_ASSESSMENT_API,
  VERIFY_OTP_API,
} from "../config/constants/apiConstant";
import {
  SIGNIN,
  STUDENT_ASSESSMENT_PAGE,
  STUDENT_CAPTURE_FACE_PAGE,
  STUDENT_GENERAL_INSTRUCTIONS_PAGE,
  STUDENT_COUNT_DOWN_PAGE,
  STUDENT_RESULT_PAGE,
  STUDENT_SUCCESS_PAGE,
  STUDENT_TIME_OUT_PAGE,
  OTP,
  RESET_PASSWORD,
  STUDENT_CANDIDATE_RESULTS_PAGE_TODAY,
  DASHBOARD_OVERALL_VIEW,
} from "./../config/constants/routePathConstant";
import { getAssessmentInfo, getQuestionList, getStartTime } from "../redux/slicers/studentSlice";
import { setUserDetails } from "../redux/slicers/authSlice.js";
import { USER_DATA } from "../config/constants/projectConstant.js";
import { handleDownloadPDF } from "../pages/questionLists/success/index.js";


export const adminLogInApi =
  (formData, setErr, navigate, setLoading, clearFormValues) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(ADMIN_SIGNIN_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          storeLocal(a?.details?.token);
          storeLocal(a?.details, USER_DATA);
          dispatch(setUserDetails(a?.details));
          clearFormValues();
          setErr("");
          navigate(DASHBOARD_OVERALL_VIEW);
        } else {
          setErr(msg);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        setErr(msg);
        devConsoleLog(e);
      })
      .send(() => {
        // setLoading(false);
      });
  };

export const signUpApi =
  (formData, setErr, navigate, setLoading, clearFormValues) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(SIGNUP_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          successToast("You have successfully registered. Kindly Login into your respective time")
          clearFormValues();
          setErr("");
          navigate(SIGNIN);
        } else if (a.statusCode === 400) {
          setErr(msg);
          // errorToast(msg);
        } else {
          setErr(msg);
          // errorToast(msg);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        // errorToast(msg);
        setErr(msg);
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };
  
export const logInApi =
  (formData, setErr, navigate, setLoading, clearFormValues) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(SIGNIN_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        // setLoading(false);
        if (a.statusCode === 200) {
          storeLocal(a?.details?.token);
          storeLocal(a?.details, USER_DATA);
          clearFormValues();
          setErr("");
          dispatch(checkTestAttendedApi(a?.details?._id,setLoading))
        } else {
          setErr(msg);
          // errorToast(msg);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        // errorToast(msg);
        setErr(msg);
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };

  export const logoutApi = (path) => {
    sessionDestroy(path);
  };
  
  export const forgotPasswordApi = (formData, setErr, navigate, setLoading,setSeconds,setResend) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(FORGOT_PASSWORD_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          sessionStorage.setItem("verify_mobile", "true");
          successToast(msg);
          navigate(`${OTP}/${formData?.mobile}`);
          setSeconds && setSeconds(30);
          setSeconds && sessionStorage.setItem("timerSeconds", 30);
          setResend && setResend(true);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        setErr(msg)
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };

  export const verifyOtpApi = (formData, setErr, navigate, setLoading) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(VERIFY_OTP_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          successToast(msg);
          navigate(`${RESET_PASSWORD}/${formData?.mobile}`);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        setErr(msg)
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };
  export const resetPasswordApi = (formData, setErr, navigate, setLoading) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(RESET_PASSWORD_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          successToast(msg);
          navigate(SIGNIN)
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        setErr(msg)
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };

export const checkTestAttendedApi = (id, setLoading, setData) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${CHECK_TEST_ATTENDED_API}/${id}`)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        const {isAttempt=false, countDownEnd, isTimedout,countDownStart} = a?.details
        setData && setData(a?.details)
        if(isAttempt) navigate(`${STUDENT_RESULT_PAGE}/${id}`)
        else if(isTimedout) navigate(`${STUDENT_TIME_OUT_PAGE}/${id}`)
        else if(!isAttempt && !countDownStart) {
          a?.details?.userDetails && storeLocal(a?.details?.userDetails?.token);
          a?.details?.userDetails && storeLocal(a?.details?.userDetails, USER_DATA);
          navigate(`${STUDENT_CAPTURE_FACE_PAGE}/${id}`)
        }
        else if(!isAttempt && countDownEnd) navigate(`${STUDENT_TIME_OUT_PAGE}/${id}`)
        else if(!isAttempt && !countDownEnd) navigate(`${STUDENT_COUNT_DOWN_PAGE}/${id}`)
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};

export const reArrangeTimeSlotApi =
(id, formData, setErr, setOpen, setLoading, clearFormValues) => (dispatch) => {
  api()
    .root(API_ROOT)
    .put(`${RE_ARRANGE_TIME_SLOT_API}/${id}`)
    .data(formData)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        setOpen(true);
        localStorage.removeItem("reAttempt")
        clearFormValues()
      } else {
        setErr(msg);
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      setErr(msg);
      devConsoleLog(e);
    })
    .send((e) => {
      setLoading(false);
    });
};
export const getSlotListApi = (convertedDate, setLoading, setSlotList) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${GET_SLOT_LIST_API}?slotDate=${convertedDate}`)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        const slots = a?.details?.map((item) => ({
          label: item?.label,
          value: item?.value,
          isDisabled:item?.availableSeats==0
        }));
        setSlotList(slots)
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};
export const captureImageApi =
  (formData, setErr, navigate, setLoading, candidateId) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(CAPTURE_IMAGE_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          setErr("");
          navigate(`${STUDENT_GENERAL_INSTRUCTIONS_PAGE}/${candidateId}`);
        } else if (a.statusCode === 400) {
          setErr(msg);
          errorToast(msg);
        } else {
          setErr(msg);
          errorToast(msg);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        errorToast(msg);
        setErr(msg);
        devConsoleLog(e);
      })
      .upload(() => {
        setLoading(false);
      });
  };

export const getQuestionsListApi = (candidateId, setLoading,setBatch) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${GET_QUESTIONS_API}&candidate_id=${candidateId}`)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        dispatch(getQuestionList(a?.details?.randomQuestions));
        setBatch(a?.details?.batchDetails)
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};

export const submitAssessmentApi =
  (formData, navigate, setLoading) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(SUBMIT_ASSESSMENT_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          navigate(`${STUDENT_SUCCESS_PAGE}/${formData?.candidate_id}`);
        } else if (a.statusCode === 400) {
          errorToast(msg);
        } else {
          errorToast(msg);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        errorToast(msg);
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };
  
export const getReportDetailApi = (candidateId, setLoading,setData, ref,setDownloadLoading) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${GET_REPORT_DETAILS_API}?id=${candidateId}`)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading && setLoading(false);
      if (a.statusCode === 200) {
        setData(a?.details?.candidateDetails)
        // ref && handleDownloadPDF(setDownloadLoading,ref)
      }
    })
    .error((e) => {
      setLoading && setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading && setLoading(false);
    });
};

export const getStatesApi = (setLoading, setData) => (dispatch) => {
  api()
    .root(API_ROOT)
    .post(GET_STATES_API)
    .data({country:"India"})
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        const states = a?.details?.states?.map((item) => ({
          label: item?.name,
          value: item?.name,
          id:item?._id
        }));
        setData(states)
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};


export const getDistrictsApi = (formValues, setLoading, setData) => (dispatch) => {
  api()
    .root(API_ROOT)
    .post(GET_DISTRICTS_API)
    .data(formValues)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        const districts = a?.details?.cities?.map((item) => ({
          label: item?.name,
          value: item?.name
        }));
        setData(districts)
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};

export const postTestStartTimeApi =
  (formData, navigate, setLoading) => (dispatch) => {
    api()
      .root(API_ROOT)
      .post(POST_TEST_START_TIME_API)
      .data(formData)
      .success((a) => {
        const { message: msg = "" } = a;
        setLoading(false);
        if (a.statusCode === 200) {
          dispatch(getStartTime(a?.details?.startTime));
          navigate(`${STUDENT_ASSESSMENT_PAGE}/${formData?.candidateId}`);
        }
      })
      .error((e) => {
        setLoading(false);
        const { message: msg = "" } = e;
        errorToast(msg);
        devConsoleLog(e);
      })
      .send(() => {
        setLoading(false);
      });
  };

export const getTestStartTimeApi = (id, setLoading) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${GET_TEST_START_TIME_API}${id}`)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        dispatch(getStartTime(a?.details?.startTime));
        dispatch(getAssessmentInfo(a?.details));
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      errorToast(msg);
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};


export const getCountdownTimeApi = (id, setLoading,setDate) => (dispatch) => {
  api()
    .root(API_ROOT)
    .get(`${GET_COUNTDOWN_TIMER_API}/${id}`)
    .success((a) => {
      const { message: msg = "" } = a;
      setLoading(false);
      if (a.statusCode === 200) {
        setDate(a?.details)
      }
    })
    .error((e) => {
      setLoading(false);
      const { message: msg = "" } = e;
      errorToast(msg);
      devConsoleLog(e);
    })
    .send(() => {
      setLoading(false);
    });
};
