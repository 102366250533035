import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import jsPDF from "jspdf";
import { Button } from "@mui/material";
import { SyncLoader } from "react-spinners";
import "./style.css";
import { getReportDetailApi } from "../../../api/studentApi";
import { capitalizeFunc } from "../../../utils/projectHelper";
import { HindSiliguriRegular } from "./encodedFont";
import congratsBadgeImg from "../../../assets/images/congrats-badge.png";
import CertificateImg from "../../../assets/images/Kamal-mitra-Yojana-Certificate.png";
import SuccessGif from "../../../assets/gifs/submitted.gif";
import FailedPage from "./../failure/index";
import html2canvas from "html2canvas";
import RatingModal from "../../../components/common/Modal/RatingModal";
import { GOOGLE_REVIEW_URL } from "../../../config/constants/projectConstant";
import { Helmet } from "react-helmet";

const Success = ({ alreadyLogin = false }) => {
  const componentRef = useRef();
  const { candidateId = "" } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getReportDetailApi(candidateId, setLoading, setData));
  }, []);

  const handleDownload = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    window.open(GOOGLE_REVIEW_URL, "_blank");
    setOpen(false);
    handleDownloadPDF(setBtnLoading, componentRef);
  };
  const handleCancel = () => {
    setOpen(false);
    handleDownloadPDF(setBtnLoading, componentRef);
  };

  const isCertified =
    data?.passingPercentage &&
    parseFloat(data?.passingPercentage).toFixed(0) >= 40;

  const content = [
    {
      img: SuccessGif,
      subTitle: "Your Test is Successfully Submitted",
      text: "Download your Certificate, click below",
      alreadyLogin: false,
    },
    {
      img: congratsBadgeImg,
      text:
        "You did a great job in the test !<br/> Download your Certificate, click below",
      alreadyLogin: true,
    },
  ];

  const filterData = content?.find(
    (item) => item?.alreadyLogin == alreadyLogin
  );

  if (loading)
    return (
      <div className="success-loader">
        <SyncLoader size="20px" color="#2ea8db" />
      </div>
    );
  if (alreadyLogin && !isCertified) return <FailedPage loading={loading} />;

  return (
    <div className="success-wrapper">
      <Helmet>
        <script>
          {`
            (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:3654106,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `}
        </script>
      </Helmet>
      <div className="success-icon">
        <img src={filterData?.img} alt="success-icon" />
      </div>
      <h1>Congratulations!</h1>
      {filterData?.subTitle && <h2>{filterData?.subTitle}</h2>}
      {isCertified && (
        <>
          <div className="download">
            <p dangerouslySetInnerHTML={{ __html: filterData?.text }}></p>
            <div>
              <Button
                variant="contained"
                className="download-btn"
                onClick={btnLoading ? undefined : handleDownload}
              >
                {btnLoading ? (
                  <div className="download-loader">
                    <SyncLoader color="#fff" />
                  </div>
                ) : (
                  "Download"
                )}
              </Button>
            </div>
          </div>
          <div style={{ position: "fixed", top: "200vh" }}>
            <div ref={componentRef}>
              <CertificatePdf {...data} />
            </div>
          </div>
          <RatingModal
            open={open}
            title="Your opinion matters to us!"
            content="We work super hard to serve you better and
            would love to know what you think of our platform"
            onClose={handleClose}
            handleCancel={handleCancel}
            handleAccept={handleAccept}
          />
        </>
      )}
    </div>
  );
};

export default Success;

export const handleDownloadPDF = async (setBtnLoading, componentRef) => {
  setBtnLoading(true);
  const doc = new jsPDF("l", "mm", [
    componentRef.current.offsetWidth,
    componentRef.current.offsetHeight,
  ]);

  const scaleFactor = 1;
  const fontFile = "../../../assets/fonts/HindSiliguri-Regular.ttf";

  doc.addFileToVFS(fontFile, HindSiliguriRegular);
  doc.addFont(fontFile, "Hind Siliguri", "normal");
  doc.setFont("Hind Siliguri");
  doc.setPage(1);

  const canvas = await html2canvas(componentRef.current, {
    scale: scaleFactor,
  });

  const imgData = canvas.toDataURL("image/jpeg", 2);

  doc.addImage(
    imgData,
    "JPEG",
    0,
    0,
    doc.internal.pageSize.getWidth(),
    doc.internal.pageSize.getHeight()
  );

  setBtnLoading(false);
  doc.save("certificate_of_completion.pdf");
};

export const CertificatePdf = (props) => {
  const {
    candidateId: {
      firstName = "",
      lastName = "",
      district = "",
      state = "",
    } = {},
    updatedAt = "",
  } = props;

  const date = getFormattedDate(updatedAt);
  return (
    <div className="cert-card">
      <img src={CertificateImg} alt="certificate" />
      <div className="text-wrap">
        <h4>
          <span>{capitalizeFunc(firstName + " " + lastName)}</span>{" "}
          <h6>from</h6>
          {district && district.trim() ? (
            <>
              <div>
                <span> {district}</span>, <span>{state}</span>
              </div>
            </>
          ) : (
            <div>
              <span> {state}</span>
            </div>
          )}
        </h4>
        <p>has successfully completed the Kamal Mitra training.</p>
      </div>
      <div className="date-wrap">
        <h4>{date}</h4>
        <div className="date-divider" />
        <p>DATE</p>
      </div>
    </div>
  );
};

const getFormattedDate = (dt) => {
  const inputDate = new Date(dt);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = inputDate.toLocaleDateString("en-US", options);
  // Swap day and month positions
  const [month, day, year] = formattedDate.split(" ");
  const finalDate = `${day} ${month} ${year}`;
  return finalDate;
};
