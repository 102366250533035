import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import Logo from "../../../../assets/images/testa-lite-logo.png";
import KamalMitraLogo from "../../../../assets/images/kamal-mitra-logo.png";
import { authSelector } from "../../../../redux/slicers/authSlice";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/helpIcon.svg";
import { ReactComponent as DownIcon } from "../../../../assets/icons/down-arrow.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone_icon.svg";
import { ReactComponent as MenuIcon } from "../../../../assets/icons/hambergermenu.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x-mark.svg";
import { useState } from "react";
import { logoutApi } from "../../../../api/studentApi";
import { getUserDetails, navigate } from "../../../../utils/projectHelper";
import { ADMIN_SIGNIN, DASHBOARD_OVERALL_VIEW } from "../../../../config/constants/routePathConstant";
import adminImg from "../../../../assets/icons/user.svg";
import { activitySelector, setMenu } from "../../../../redux/slicers/activitySlice";

const NavBar = ({ role = "guest" }) => {
  const boxRef = useRef(null);
  const { userDetails = {} } = useSelector(authSelector);
  const { firstName = "", lastName = "", userType = "" } = userDetails;
  const [profileDropdown, setProfileDropdown] = useState(false);
  const fullName = firstName + " " + lastName;

  const USERTYPE = getUserDetails();
  const iskamalMitraAdmin = USERTYPE?.firstName == "Kamalmitra";
  const isAdmin = USERTYPE?.firstName !== "admin";

  const handleLogout = () => {
    if (role == "admin") {
      logoutApi(ADMIN_SIGNIN);
    } else {
      logoutApi();
    }
    setProfileDropdown(false);
  };

  const handleLogo = () => {
    navigate(DASHBOARD_OVERALL_VIEW);
  };
  const dispatch = useDispatch();
  const menuState = useSelector(activitySelector).isMenuOpen;
  const handleMenu = () => {
    dispatch(setMenu(!menuState));
  };

  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setProfileDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <nav>
      <div className="student-nav">
        <div className="logo-wrapper">
          <div className="logo">
            <img src={Logo} alt="logo" onClick={handleLogo} />
          </div>
          {isAdmin && (
            <div className="kamal-logo">
              <img src={KamalMitraLogo} alt="logo" />
            </div>
          )}
        </div>
        <div className="nav-right">
          {role == "admin" && (
            <>
              <div className="dropdown" onClick={(e) => e.stopPropagation()}>
                <div className="nav-admin" onClick={() => setProfileDropdown(true)}>
                  <img className="admin-img" src={adminImg} alt="admin" />
                  <p>{USERTYPE?.firstName}</p>
                  <DownIcon />
                </div>
                <div
                  className="dropdown-options"
                  ref={boxRef}
                  id="drop-down"
                  style={{ display: profileDropdown ? "block" : "none" }}
                >
                  <div className="option">
                    {iskamalMitraAdmin ? (
                      <div className="role-type">
                        <div className="role-contact">Help & Support</div>
                        <div className="role-number">
                          <span>
                            <PhoneIcon />
                          </span>
                          8851928693
                        </div>
                        <div className="role-number">
                          <span>
                            <PhoneIcon />
                          </span>
                          8929486910
                        </div>
                        <div className="role-number" onClick={handleLogout}>
                          <span>
                            <HelpIcon />
                          </span>
                          Log out
                        </div>
                      </div>
                    ) : (
                      <div className="role-type">
                        <p className="check-admin">{USERTYPE?.firstName}</p>
                        <div className="role-number" onClick={handleLogout}>
                          <span>
                            <HelpIcon />
                          </span>
                          Log out
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div class="sidebar-view-btn">
                <button onClick={handleMenu}>{!menuState ? <MenuIcon /> : <CloseIcon />}</button>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
