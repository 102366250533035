// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.failed-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(98vh - 130px);
}
.failed-page .container .main {
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.failed-page .container .title {
  font-family: Poppins;
  font-size: 40px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #000000;
  margin-bottom: 20px;
}
.failed-page .container .desc {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.01em;
  text-align: center;
  color: #797979;
}
.failed-page .container .re-attempt-btn-wrapper {
  margin: 20px 0 ;
}
.failed-page .container .re-attempt-btn-wrapper .re-attempt-btn{
  padding: 15px 30px 15px 30px;
  border-radius: 5px;
  gap: 10px;
  background: #4f547b;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: unset;
  color: white;
}
@media (max-width: 576px) {
  .failed-page .container .title {
    font-size: 25px;
    margin: 0;
  }
  .failed-page .container .desc {
    font-size: 17px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/questionLists/failure/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,0BAA0B;AAC5B;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,SAAS;EACT,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,qBAAqB;EACrB,YAAY;AACd;AACA;EACE;IACE,eAAe;IACf,SAAS;EACX;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".failed-page {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: calc(98vh - 130px);\n}\n.failed-page .container .main {\n  max-width: 750px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.failed-page .container .title {\n  font-family: Poppins;\n  font-size: 40px;\n  font-weight: 600;\n  line-height: 36px;\n  letter-spacing: 0.01em;\n  text-align: center;\n  color: #000000;\n  margin-bottom: 20px;\n}\n.failed-page .container .desc {\n  font-family: Poppins;\n  font-size: 18px;\n  font-weight: 400;\n  line-height: 27px;\n  letter-spacing: 0.01em;\n  text-align: center;\n  color: #797979;\n}\n.failed-page .container .re-attempt-btn-wrapper {\n  margin: 20px 0 ;\n}\n.failed-page .container .re-attempt-btn-wrapper .re-attempt-btn{\n  padding: 15px 30px 15px 30px;\n  border-radius: 5px;\n  gap: 10px;\n  background: #4f547b;\n  font-family: Poppins;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  text-transform: unset;\n  color: white;\n}\n@media (max-width: 576px) {\n  .failed-page .container .title {\n    font-size: 25px;\n    margin: 0;\n  }\n  .failed-page .container .desc {\n    font-size: 17px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
