import React from "react";
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";

const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
];

const Gauge = ({ value, min, max }) => {
  const backgroundArc = arc()
    .innerRadius(0.85)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(1)();

  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);

  const angle = angleScale(percent);

  const filledArc = arc()
    .innerRadius(0.85)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(1)();

  const colorScale = scaleLinear().domain([0, 1]).range(["#6f73b073", "#6f73b0"]);
  const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value));
  const markerLocation = getCoordsOnArc(angle, 1 - (1 - 0.85) / 2);

  return (
    <div style={{ position: "relative", width: "100%", fontFamily: "Poppins" }}>
      <svg style={{ overflow: "visible" }} viewBox={[-1, -1, 2, 1].join(" ")}>
        <defs>
          <linearGradient id="Gauge__gradient" gradientUnits="userSpaceOnUse" x1="-1" x2="1" y2="0">
            {gradientSteps.map((color, index) => (
              <stop key={color} stopColor={color} offset={`${index / (gradientSteps.length - 1)}`} />
            ))}
          </linearGradient>
        </defs>
        <path d={backgroundArc} fill="#dbdbe7" />
        <path d={filledArc} fill="url(#Gauge__gradient)" />

        {/* Add three lines to separate the filledArc */}
        {Array.from({ length: 3 }).map((_, index) => {
          const angleOffset = (index + 3) * (Math.PI / 4); // Divide 180 degrees into 4 parts
          const startX = Math.cos(Math.PI / 2 + angleOffset) * 0.85;
          const startY = Math.sin(Math.PI / 2 + angleOffset) * 0.85;
          const endX = Math.cos(Math.PI / 2 + angleOffset) * 1;
          const endY = Math.sin(Math.PI / 2 + angleOffset) * 1;
          return <line key={index} x1={startX} y1={startY} x2={endX} y2={endY} stroke="#fff" strokeWidth="0.027" />;
        })}
        <circle
          cx={markerLocation[0]}
          cy={markerLocation[1]}
          r="0.07"
          stroke="#fff"
          strokeWidth="0.04"
          fill={colorScale(percent)}
        />
      </svg>
      <p
        style={{
          fontSize: "35px",
          fontWeight: "900",
          position: "absolute",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        {value ? value : 0}
      </p>
    </div>
  );
};

export default Gauge;
